export const Departamentos = [
   { id_departamentos: 1, departamentos: "Amazonas" },
   { id_departamentos: 2, departamentos: "Antioquia" },
   { id_departamentos: 3, departamentos: "Arauca" },
   { id_departamentos: 4, departamentos: "Archipiélago San Andrés, Providencia y Santa Catalina" },
   { id_departamentos: 5, departamentos: "Atlántico" },
   { id_departamentos: 6, departamentos: "Bogotá D.C." },
   { id_departamentos: 7, departamentos: "Bolívar" },
   { id_departamentos: 8, departamentos: "Boyacá" },
   { id_departamentos: 9, departamentos: "Caldas" },
   { id_departamentos: 10, departamentos: "Caquetá" },
   { id_departamentos: 11, departamentos: "Casanare" },
   { id_departamentos: 12, departamentos: "Cauca" },
   { id_departamentos: 13, departamentos: "Cesar" },
   { id_departamentos: 14, departamentos: "Chocó" },
   { id_departamentos: 15, departamentos: "Córdoba" },
   { id_departamentos: 16, departamentos: "Cundinamarca" },
   { id_departamentos: 17, departamentos: "Guainía" },
   { id_departamentos: 18, departamentos: "Guaviare" },
   { id_departamentos: 19, departamentos: "Huila" },
   { id_departamentos: 20, departamentos: "La Guajira" },
   { id_departamentos: 21, departamentos: "Magdalena" },
   { id_departamentos: 22, departamentos: "Meta" },
   { id_departamentos: 23, departamentos: "Nariño" },
   { id_departamentos: 24, departamentos: "Norte de Santander" },
   { id_departamentos: 25, departamentos: "Putumayo" },
   { id_departamentos: 26, departamentos: "Quindío" },
   { id_departamentos: 27, departamentos: "Risaralda" },
   { id_departamentos: 28, departamentos: "Santander" },
   { id_departamentos: 29, departamentos: "Sucre" },
   { id_departamentos: 30, departamentos: "Tolima" },
   { id_departamentos: 31, departamentos: "Valle del Cauca" },
   { id_departamentos: 32, departamentos: "Vaupés" },
   { id_departamentos: 33, departamentos: "Vichada" }
]