export const EPS = [
   { id_eps: 1, eps: "E.P.S. SURAMERICANA S.A." },
   { id_eps: 2, eps: "SANITAS S.A. E.P.S." },
   { id_eps: 3, eps: "ALIANSALUD E.P.S. S.A." },
   { id_eps: 4, eps: "NUEVA E.P.S." },
   { id_eps: 5, eps: "COMPENSAR E.P.S." },
   { id_eps: 6, eps: "SALUD TOTAL S.A. E.P.S." },
   { id_eps: 7, eps: "E.P.S. FAMISANAR LTDA" },
   { id_eps: 8, eps: "SALUDVIDA E.P.S. S.A." },
   { id_eps: 9, eps: "COOMEVA E.P.S. S.A." },
   { id_eps: 10, eps: "SERV. OCCI. DE SALUD SOS E.P.S." },
   { id_eps: 11, eps: "COMFENALCO VALLE E.P.S." },
   { id_eps: 12, eps: "CRUZ BLANCA E.P.S. S.A." },
   { id_eps: 13, eps: "CAFÉSALUD E.P.S." },
   { id_eps: 14, eps: "ASOCIACIÓN MUTUAL SER ESS" },
   { id_eps: 15, eps: "CCF DE LA GUAJIRA" },
   { id_eps: 16, eps: "AMBUQ ARS" },
   { id_eps: 17, eps: "COOSALUD E.P.S." },
   { id_eps: 18, eps: "COMFACOR" },
   { id_eps: 19, eps: "COMFAORIENTE" },
   { id_eps: 20, eps: "COMFASUCRE" },
   { id_eps: 21, eps: "CCF CAJACOPI ATLÁNTICO" },
   { id_eps: 22, eps: "EMDISALUD E.S.S." },
   { id_eps: 23, eps: "ECOOPSOS ESS" },
   { id_eps: 24, eps: "COMFAMILIAR CARTAGENA" },
   { id_eps: 25, eps: "NUEVA E.P.S." },
   { id_eps: 26, eps: "ASMET SALUD" },
   { id_eps: 27, eps: "COMPARTA" },
   { id_eps: 28, eps: "SAVIA SALUD E.P.S." },
   { id_eps: 29, eps: "SALUDVIDA E.P.S." },
   { id_eps: 30, eps: "COMFABOY" },
   { id_eps: 31, eps: "COMFAMILIAR CHOCO" },
   { id_eps: 32, eps: "CAPITAL SALUD E.P.S." },
   { id_eps: 33, eps: "COMFACUNDI" },
   { id_eps: 34, eps: "A.R.S. CONVIDA" },
   { id_eps: 35, eps: "CAFÉSALUD E.P.S." },
   { id_eps: 36, eps: "COMFAMILIAR HUILA" },
   { id_eps: 37, eps: "CAPRESOCA E.P.S." },
   { id_eps: 38, eps: "EMSSANAR ESS" },
   { id_eps: 39, eps: "CCF DE NARIÑO" },
   { id_eps: 40, eps: "ASOCIACIÓN INDÍGENA DEL CAUCA" },
   { id_eps: 41, eps: "DUSAKAWI A.R.S.I." },
   { id_eps: 42, eps: "PIJAOS SALUD E.P.S.I" },
   { id_eps: 43, eps: "ANAS WAYUU E.P.S. INDIGENA" },
   { id_eps: 44, eps: "MANEXKA E.P.S." },
   { id_eps: 45, eps: "MALLAMAS E.P.S.I" }
]