export const ItemCariesDental = {
   fila1: {
      O: [
         'd_16.O.con', 'd_16.O.car',
         'd_55.O.con', 'd_55.O.car',
         'd_54.O.con', 'd_54.O.car',
         'dd', 'dd', 'dd', 'dd', 'dd', 'dd',
         'dd', 'dd', 'dd', 'dd', 'dd', 'dd',
         'd_64.O.con', 'd_64.O.car',
         'd_65.O.con', 'd_65.O.car',
         'd_26.O.con', 'd_26.O.car',
      ],
      M: [
         'd_16.M.con', 'd_16.M.car',
         'd_55.M.con', 'd_55.M.car',
         'd_54.M.con', 'd_54.M.car',
         'd_53.M.con', 'd_53.M.car',
         'd_52.M.con', 'd_52.M.car',
         'd_51.M.con', 'd_51.M.car',
         'd_61.M.con', 'd_61.M.car',
         'd_62.M.con', 'd_62.M.car',
         'd_63.M.con', 'd_63.M.car',
         'd_64.M.con', 'd_64.M.car',
         'd_65.M.con', 'd_65.M.car',
         'd_26.M.con', 'd_26.M.car',
      ],
      D: [
         'd_16.D.con', 'd_16.D.car',
         'd_55.D.con', 'd_55.D.car',
         'd_54.D.con', 'd_54.D.car',
         'd_53.D.con', 'd_53.D.car',
         'd_52.D.con', 'd_52.D.car',
         'd_51.D.con', 'd_51.D.car',
         'd_61.D.con', 'd_61.D.car',
         'd_62.D.con', 'd_62.D.car',
         'd_63.D.con', 'd_63.D.car',
         'd_64.D.con', 'd_64.D.car',
         'd_65.D.con', 'd_65.D.car',
         'd_26.D.con', 'd_26.D.car',
      ],
      V: [
         'd_16.V.con', 'd_16.V.car',
         'd_55.V.con', 'd_55.V.car',
         'd_54.V.con', 'd_54.V.car',
         'd_53.V.con', 'd_53.V.car',
         'd_52.V.con', 'd_52.V.car',
         'd_51.V.con', 'd_51.V.car',
         'd_61.V.con', 'd_61.V.car',
         'd_62.V.con', 'd_62.V.car',
         'd_63.V.con', 'd_63.V.car',
         'd_64.V.con', 'd_64.V.car',
         'd_65.V.con', 'd_65.V.car',
         'd_26.V.con', 'd_26.V.car',
      ],
      P: [
         'd_16.P.con', 'd_16.P.car',
         'd_55.P.con', 'd_55.P.car',
         'd_54.P.con', 'd_54.P.car',
         'd_53.P.con', 'd_53.P.car',
         'd_52.P.con', 'd_52.P.car',
         'd_51.P.con', 'd_51.P.car',
         'd_61.P.con', 'd_61.P.car',
         'd_62.P.con', 'd_62.P.car',
         'd_63.P.con', 'd_63.P.car',
         'd_64.P.con', 'd_64.P.car',
         'd_65.P.con', 'd_65.P.car',
         'd_26.P.con', 'd_26.P.car',
      ]
   },
   fila2: {
      O: [
         'd_46.O.con', 'd_46.O.car',
         'd_85.O.con', 'd_85.O.car',
         'd_84.O.con', 'd_84.O.car',
         'dd', 'dd', 'dd', 'dd', 'dd', 'dd',
         'dd', 'dd', 'dd', 'dd', 'dd', 'dd',
         'd_74.O.con', 'd_74.O.car',
         'd_75.O.con', 'd_75.O.car',
         'd_36.O.con', 'd_36.O.car',
      ],
      M: [
         'd_46.M.con', 'd_46.M.car',
         'd_85.M.con', 'd_85.M.car',
         'd_84.M.con', 'd_84.M.car',
         'd_83.M.con', 'd_83.M.car',
         'd_82.M.con', 'd_82.M.car',
         'd_81.M.con', 'd_81.M.car',
         'd_71.M.con', 'd_71.M.car',
         'd_72.M.con', 'd_72.M.car',
         'd_73.M.con', 'd_73.M.car',
         'd_74.M.con', 'd_74.M.car',
         'd_75.M.con', 'd_75.M.car',
         'd_36.M.con', 'd_36.M.car',
      ],
      D: [
         'd_46.D.con', 'd_46.D.car',
         'd_85.D.con', 'd_85.D.car',
         'd_84.D.con', 'd_84.D.car',
         'd_83.D.con', 'd_83.D.car',
         'd_82.D.con', 'd_82.D.car',
         'd_81.D.con', 'd_81.D.car',
         'd_71.D.con', 'd_71.D.car',
         'd_72.D.con', 'd_72.D.car',
         'd_73.D.con', 'd_73.D.car',
         'd_74.D.con', 'd_74.D.car',
         'd_75.D.con', 'd_75.D.car',
         'd_36.D.con', 'd_36.D.car',
      ],
      V: [
         'd_46.V.con', 'd_46.V.car',
         'd_85.V.con', 'd_85.V.car',
         'd_84.V.con', 'd_84.V.car',
         'd_83.V.con', 'd_83.V.car',
         'd_82.V.con', 'd_82.V.car',
         'd_81.V.con', 'd_81.V.car',
         'd_71.V.con', 'd_71.V.car',
         'd_72.V.con', 'd_72.V.car',
         'd_73.V.con', 'd_73.V.car',
         'd_74.V.con', 'd_74.V.car',
         'd_75.V.con', 'd_75.V.car',
         'd_36.V.con', 'd_36.V.car',
      ],
      L: [
         'd_46.L.con', 'd_46.L.car',
         'd_85.L.con', 'd_85.L.car',
         'd_84.L.con', 'd_84.L.car',
         'd_83.L.con', 'd_83.L.car',
         'd_82.L.con', 'd_82.L.car',
         'd_81.L.con', 'd_81.L.car',
         'd_71.L.con', 'd_71.L.car',
         'd_72.L.con', 'd_72.L.car',
         'd_73.L.con', 'd_73.L.car',
         'd_74.L.con', 'd_74.L.car',
         'd_75.L.con', 'd_75.L.car',
         'd_36.L.con', 'd_36.L.car',
      ]
   }
}